<template>
    <div id="faq" class="page-wrapper page-faq">
        <section class="faq-wrap site-max-width-reading">
            <h2 class="small-title suptitle" data-inview="fadeInUp" data-delay="100" v-if="pageIsLoaded">{{ content.introBlock1.surtitre }}</h2>
            <h1 class="big-title title" data-inview="fadeInUp" data-delay="300" v-if="pageIsLoaded"><span v-html="content.introBlock1.titre"></span></h1>

            <div class="faq-list" data-inview="fadeInUp" :data-delay="400" v-if="pageIsLoaded">
                <article class="faq-item" v-for="(item, i) in content.faq" :key="i" :class="{ 'is-active': faqOpenIndex === i }" >
                    <h3 class="faq-title" @click.prevent="faqOpen(i)">
                        {{ item.titre }}
                        <svg width="18" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7006 1.67238L16.835 6.8057L-4.01896e-07 6.8057L-2.97483e-07 9.19438L16.835 9.19438L11.7006 14.3277L13.3729 16L21.3717 8L13.3728 3.69128e-07L11.7006 1.67238Z" fill="white"/>
                        </svg>
                    </h3>
                    <p class="faq-desc">
                        <span v-html="item.texte"></span>
                    </p>
                </article>
            </div>
        </section>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'Faq',

    data() {
        return {
            faqOpenIndex: false
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()
                }
            },
            immediate: true,
        },
    },

    mounted () {

    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        faqOpen(index) {
            this.faqOpenIndex = index
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
